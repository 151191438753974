.has-selection {
  border: 2px solid orange;
  box-shadow: 0 3px 4px rgba(255, 128, 0, 0.4);
}

select option:disabled {
  color: #aaa;
}

.plant-select {
  background-color: #3b65a9!important;
  border: 1px solid #304f82!important;
  border-radius: 0!important;
  color: white!important;
  box-shadow: 0 0 #000000!important;
}